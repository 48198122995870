import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";

function MobileFileUpload({ innerText, accept, selectedFileBase64 }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        selectedFileBase64(reader.result);
      };
    }
    // Perform further actions with the selected file
  };
  return (
    <div>
      <button
        className="border border-primary w-full py-2 text-primary font-medium"
        onClick={handleButtonClick}
        type="button"
      >
        <FontAwesomeIcon icon={faUpload} className="mr-2" />
        {innerText}
      </button>
      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default MobileFileUpload;
