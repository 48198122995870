import React from "react";

function DisabledPersonalImg() {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="44"
        height="44"
        rx="22"
        fill="#BBBBBB"
        fillOpacity="0.35"
      />
      <path
        d="M22.6596 20.87C22.5596 20.86 22.4396 20.86 22.3296 20.87C19.9496 20.79 18.0596 18.84 18.0596 16.44C18.0596 13.99 20.0396 12 22.4996 12C24.9496 12 26.9396 13.99 26.9396 16.44C26.9296 18.84 25.0396 20.79 22.6596 20.87Z"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6597 24.56C15.2397 26.18 15.2397 28.82 17.6597 30.43C20.4097 32.27 24.9197 32.27 27.6697 30.43C30.0897 28.81 30.0897 26.17 27.6697 24.56C24.9297 22.73 20.4197 22.73 17.6597 24.56Z"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DisabledPersonalImg;
