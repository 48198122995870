import React from "react";
import MobileNavigation from "./screens/mobile/navigation";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import Store from "./redux";
import { useMediaQuery } from "react-responsive";
import DesktopNavigation from "./screens/desktop/navigation";
function App() {
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const isLargeScreen = useMediaQuery({ minWidth: 768 });
  return (
    <BrowserRouter>
      <Provider store={Store}>
        {isSmallScreen && <MobileNavigation />}
        {isLargeScreen && <DesktopNavigation />}
      </Provider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition="Bounce"
      />
    </BrowserRouter>
  );
}

export default App;
