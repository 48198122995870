import { createSlice } from "@reduxjs/toolkit";

const FormStatusSlice = createSlice({
  initialState: {
    firstStepSubmitted: false,
    secondStepSubmitted: false,
    thirdStepSubmitted: false,
  },
  name: "formStatusSlice",
  reducers: {
    setFirstFormStatus: (state, actions) => {
      state.firstStepSubmitted = actions.payload;
    },
    setSecondFormStatus: (state, actions) => {
      state.secondStepSubmitted = actions.payload;
    },
    setThirdFormStatus: (state, actions) => {
      state.thirdStepSubmitted = actions.payload;
    },
  },
});

export default FormStatusSlice.reducer;
export const { setFirstFormStatus, setSecondFormStatus, setThirdFormStatus } =
  FormStatusSlice.actions;
