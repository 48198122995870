import React, { useEffect } from "react";
import MobileAccordion from "../../components/mobile/accordion";
import MobileFileUpload from "../../components/mobile/fileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  setFirstFormStatus,
  setSecondFormStatus,
  setThirdFormStatus,
} from "../../redux/formStatusStore";
import { SuccessToast } from "../../components/mobile/toastify";

function MobileTermsandConditions() {
  const { secondStepSubmitted, thirdStepSubmitted } = useSelector(
    (state) => state.formStatusStore
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("thirdStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
      dispatch(setThirdFormStatus(true));
    }
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("terms-conditions-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          terms: "",
          acceptancename: "",
          signature: "",
        };
  };
  const termsForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      terms: Yup.string()
        .test(
          "yes",
          `You should accept Terms and Conditions to continue`,
          function (value) {
            return value === "yes";
          }
        )
        .required("Required"),
      acceptancename: Yup.string().required("Required"),
      signature: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(setThirdFormStatus(true));
      localStorage.setItem("thirdStepSubmitted", true);
      localStorage.setItem("terms-conditions-info", JSON.stringify(values));
      SuccessToast("Data Saved! ");
    },
  });

  function InnerContent() {
    return (
      <div className="py-2">
        <div className="">
          <p className="text-black font-medium">Terms & Conditions</p>
          <div className="bg-gray-200 p-2 border border-gray-400 text-gray-600">
            <p>
              I declare that information given above are correct and true to the
              best of my knowledge and belief. If I am selected, I agree to be
              bound by the rules and regulations of the school now in force and
              by those to be made from time in future.
            </p>{" "}
            <p>
              {" "}
              I Promise that will not calm any compensation or refund of my fees
              paid by me. I also promise that I will retain from indulging in
              any activities that is determined to the good name of the school
              and I bring myself for any action to be taken against me by school
              if indulge in any such activities.
            </p>{" "}
            <p>
              I Promise to pay the school the entire course and other fees
              prescribed by the school if I will fail to continue the course at
              any time after joining the course.
            </p>{" "}
            <p>
              {" "}
              I do hereby agree to pay the cost of damage caused to the movable
              and immovable property of the school due to neglect of duties /
              work.
            </p>{" "}
            <p>
              I will not keep myself absent from the classes without obtaining
              prior permission from the Principal / Director. I am aware that
              the Tamil Nadu Technical Training School is self financing job
              oriented technical training school.
            </p>{" "}
            <p>
              I Shall not use any type of intoxicants / drugs etc., In the
              premises of the Hostel and School and assure to maintain high
              standard of character, behaviour and hygine during my stay &
              training period in this school.
            </p>{" "}
            <p>
              I Promise to pay all the fees each for One / Two years Course and
              incase I fail to pay the fees in time or on demand the management
              of Tamil Nadu Technical Training School will have right to cancel
              my admission and I will not proceed in any court of law in the
              respect.
            </p>{" "}
            <p>
              I and my parents / Guardians have carefully gone through the
              Prospectus. Rules and Regulations & Terms & Conditions of
              Tamilnadu Technical Training School and at my own will and desire,
              Please accept my admission from and oblige.
            </p>
          </div>
          <div className="my-2 px-2">
            <label className="font-medium">
              Are you agreed Terms and Conditions
              <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-start items-center gap-x-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="terms"
                  className="h-4 w-4 mr-2"
                  value="yes"
                  checked={termsForm.values.terms === "yes"}
                  onChange={termsForm.handleChange}
                />
                <label className="font-medium">Yes</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="terms"
                  className="h-4 w-4 mr-2"
                  value="no"
                  checked={termsForm.values.terms === "no"}
                  onChange={termsForm.handleChange}
                />
                <label className="font-medium">No</label>
              </div>
              <p className="text-red-500 font-medium text-md">
                {termsForm.touched?.terms && termsForm?.errors?.terms}
              </p>
            </div>
          </div>
          <p className="text-black font-medium">Declaration</p>
          <div className="bg-gray-200 p-2 border border-gray-400 text-gray-600">
            <p>
              I hereby decleare that I hold myself responsible for the timely
              payment of all dues payable to the Tamilnadu Technical Training
              School, In Respect of his / her ward named
            </p>
            <p>
              ----------------------------------------------------------------------
            </p>
            <p>
              during the period of his / her study as per rules of management
              amended from time to time at the school and there after till the
              accounts are closed. I hold myself empossible for the desciplinary
              behaviour of ward.
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Enter Your name to accept Declearations
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter your Name"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="acceptancename"
              defaultValue={termsForm.values.acceptancename}
              onChange={termsForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {termsForm.touched?.acceptancename &&
                termsForm?.errors?.acceptancename}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Signature Photo
              <span className="text-red-500">*</span>
            </label>
            <MobileFileUpload
              innerText={"Upload Photo"}
              selectedFileBase64={(value) =>
                termsForm.setFieldValue("signature", value)
              }
              accept={"image/*"}
            />
            {termsForm?.values?.signature && (
              <p className="font-medium">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-600"
                />{" "}
                File Uploaded
              </p>
            )}
            <p className="text-red-500 font-medium text-md">
              {termsForm.touched?.signature && termsForm?.errors?.signature}
            </p>
          </div>
          <div className="text-right px-2 mb-2">
            <button className="bg-secondary px-5 text-white py-2 font-medium">
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <form onSubmit={termsForm.handleSubmit}>
        <MobileAccordion
          acordionTitle={"Terms and Conditions"}
          disabled={!secondStepSubmitted && !thirdStepSubmitted}
          openAccordion={secondStepSubmitted && !thirdStepSubmitted}
          savedAccordion={thirdStepSubmitted}
          accordionContent={InnerContent()}
          accordionTitleClass={"bg-primary rounded-t-md text-white"}
          outerDivClass={"my-2"}
          EnableEdit={() => {
            dispatch(setFirstFormStatus(true));
            dispatch(setSecondFormStatus(true));
            dispatch(setThirdFormStatus(false));
          }}
        />
      </form>
    </div>
  );
}

export default MobileTermsandConditions;
