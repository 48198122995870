import React from "react";
import DesktopSections from "./sections";
import { Outlet } from "react-router-dom";

function DesktopForm() {
  return (
    <>
      <DesktopSections />
      <Outlet />
    </>
  );
}

export default DesktopForm;
