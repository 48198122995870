import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DesktopCourseDetailsForm from "./courseDetailsForm";
import DesktopPersonalDetailsForm from "./personalInfoForm";
import DesktopTermsnandConditionsForm from "./termsandconditionsForm";
import DesktopForm from "./form";
import DesktopConfirmDetails from "./confirmationDetails";
function DesktopNavigation() {
  return (
    <Routes>
      <Route path="/" Component={DesktopForm}>
        <Route index element={<Navigate to="course-details" />} />
        <Route path="course-details" Component={DesktopCourseDetailsForm} />
        <Route path="personal-details" Component={DesktopPersonalDetailsForm} />
        <Route
          path="terms-conditions"
          Component={DesktopTermsnandConditionsForm}
        />
        <Route path="confirm-details" Component={DesktopConfirmDetails} />
      </Route>
    </Routes>
  );
}

export default DesktopNavigation;
