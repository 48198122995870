import React from "react";
import { useSelector } from "react-redux";
import SelectedCourseImg from "../../assets/images/svg/Desktop/selectedCourseImg";
import SelectedPersonalImg from "../../assets/images/svg/Desktop/selectedPersonalImg";
import SelectedTermsImg from "../../assets/images/svg/Desktop/selectedTermsImg";
import SelectedConfirmImg from "../../assets/images/svg/Desktop/selectedConfirmImg";
import DisabledConfirmImg from "../../assets/images/svg/Desktop/disabledConfirmImg";
import DisabledCourseImg from "../../assets/images/svg/Desktop/disabledCourseImg";
import DisabledPersonalImg from "../../assets/images/svg/Desktop/disabledPersonalImg";
import DisabledTermsImg from "../../assets/images/svg/Desktop/disabledTermsImg";
function DesktopSections() {
  const logo = require("../../assets/images/png/logo.png");
  const { firstStepSubmitted, secondStepSubmitted, thirdStepSubmitted } =
    useSelector((state) => state.formStatusStore);
  return (
    <div>
      <div className="flex justify-center my-3">
        <div className="container flex items-center">
          <div className="w-1/5 flex justify-end">
            <img src={logo} alt="" className="h-[15vh]" />
          </div>
          <div className="w-4/5 text-center">
            <p className="text-3xl font-medium text-[#2B3690]">
              Tamilnadu Technical Training School and Paramedical Institute
            </p>
            <p className="font-medium text-lg">Run by</p>
            <p className="font-medium text-lg">
              Tamilnadu Santhome Educational & Charitable Trust
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-evenly w-full h-[84px]">
        <div className="flex items-center">
          {!firstStepSubmitted ? <SelectedCourseImg /> : <DisabledCourseImg />}
          <label className="ml-2 mr-[12px]">Course Details</label>
        </div>
        <div className="flex items-center">
          {firstStepSubmitted && !secondStepSubmitted ? (
            <SelectedPersonalImg />
          ) : (
            <DisabledPersonalImg />
          )}
          <label className="ml-2 mr-[12px]">Personal Details</label>
        </div>
        <div className="flex items-center">
          {secondStepSubmitted && !thirdStepSubmitted ? (
            <SelectedTermsImg />
          ) : (
            <DisabledTermsImg />
          )}
          <label className="ml-2 mr-[12px]">Terms and Conditions</label>
        </div>
        <div className="flex items-center">
          {thirdStepSubmitted ? <SelectedConfirmImg /> : <DisabledConfirmImg />}
          <label className="ml-2 mr-[12px]">Confirm Details</label>
        </div>
      </div>
    </div>
  );
}

export default DesktopSections;
