import React, { useEffect, useState } from "react";
import MobileAccordion from "../../components/mobile/accordion";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setFirstFormStatus,
  setSecondFormStatus,
  setThirdFormStatus,
} from "../../redux/formStatusStore";
import axios from "axios";
import { FailureToast } from "../../components/mobile/toastify";

function MobileConfirmationDetails() {
  const { firstStepSubmitted, secondStepSubmitted, thirdStepSubmitted } =
    useSelector((state) => state.formStatusStore);
  const dispatch = useDispatch();
  const [courseList, setCourseList] = useState([]);
  const CourseDetails = JSON.parse(localStorage.getItem("course-details-info"));
  const PersonalDetails = JSON.parse(
    localStorage.getItem("personal-details-info")
  );

  useEffect(() => {
    if (localStorage.getItem("thirdStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
      dispatch(setThirdFormStatus(true));
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const CourseName = (id) => {
    const filterData = courseList.filter((items) => items.value === id);
    if (filterData.length > 0) return filterData[0]["label"];
  };
  function InnerContent() {
    return (
      <div>
        <div className="text-start text-white p-3 font-medium rounded-md bg-[#6457FF]">
          Course Details
        </div>
        <div className="flex flex-col md:flex-row justify-evenly py-4">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <p className="font-medium">Course</p>
            <p className="text-gray-600 font-medium">{CourseName(CourseDetails?.course)}</p>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-2 md:mt-0">
            <p className="font-medium">Center</p>
            <p className="text-gray-600 font-medium">{CourseDetails?.center.toUpperCase()}</p>
          </div>
        </div>
        {CourseDetails?.qualification?.length > 0 &&
          CourseDetails?.qualification?.map((item, index) => (
            <div className="border p-4" key={index}>
              <div className="flex items-center space-x-3 md:flex-row  bg-[#6457FF] p-2 text-sm">
                <p className="text-white font-medium">
                  Qualification - {index + 1}
                </p>
                <div className="flex md:flex-row text-white">
                  <p className="font-medium text-gray-300">
                    {item.from.replaceAll("-", "/")}
                  </p>
                  <p className="font-medium text-gray-300">-</p>
                  <p className="font-medium text-gray-300">
                    {item.to.replaceAll("-", "/")}
                  </p>
                </div>
              </div>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="py-3">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <p className="font-medium">Name of Examination Passed</p>
                    <p className="font-medium text-gray-500">{item.examname}</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <p className="font-medium">Register Number</p>
                    <p className="font-medium text-gray-500">{item.regno}</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <p className="font-medium">Marks</p>
                    <p className="font-medium text-gray-500">{item.marks}%</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <p className="font-medium">Name of Board</p>
                    <p className="font-medium text-gray-500">
                      {item.nameofboard}
                    </p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <p className="font-medium">School / College Name</p>
                    <p className="font-medium text-gray-500">{item.school}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="mt-4 text-start text-white p-3 font-medium rounded-md bg-[#6457FF]">
          Personal Details
        </div>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Applicant Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.applicantname}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Date of Birth</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.dob}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Gender</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.gender}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Adhar Number</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.adharnumber}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Father Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fathername}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Father Occupation</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fatheroccupation}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Father Contact</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fathercontact}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Mother Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothername}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Mother Contact</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothercontact}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Religion</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.religion}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Blood Group</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.bloodgroup}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Marital Status</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.maritalstatus}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Mobile</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mobile}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Nationality</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.nationality}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Mother Tongue</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothertongue}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Email</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.email}
              </p>
            </div>
          </div>
          <div className="py-3 col-span-1 md:col-span-2 lg:col-span-4">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Full Address</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.address}, {PersonalDetails?.taluk},{" "}
                {PersonalDetails?.district} - {PersonalDetails?.pincode}
              </p>
            </div>
          </div>
          <div className="py-3 col-span-1 md:col-span-2 lg:col-span-4">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <p className="font-medium">Are you Physically Handicapped</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.handicapped}
              </p>
            </div>
          </div>
        </div>

        <div className="p-2 w-full">
          <div className="flex items-center justify-end p-3">
            <button
              id="submit-btn"
              className="bg-[#6457FF] text-white font-bold py-2 px-4 mr-4 flex items-center"
            >
              Proceed to Apply
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M14.4302 6.42999L20.5002 12.5L14.4302 18.57"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M3.5 12.5H20.33"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
  console.log(firstStepSubmitted, secondStepSubmitted, thirdStepSubmitted);
  return (
    <MobileAccordion
      acordionTitle={"Confirm Details"}
      disabled={
        !firstStepSubmitted || !secondStepSubmitted || !thirdStepSubmitted
      }
      openAccordion={thirdStepSubmitted}
      accordionContent={InnerContent()}
      accordionTitleClass={"bg-primary rounded-t-md text-white"}
      outerDivClass={"my-2"}
    />
  );
}

export default MobileConfirmationDetails;
