import React from "react";
import { Link } from "react-router-dom";
function MobileLandingPage() {
  const logo = require("../../assets/images/png/logo.png");
  const rightArrow = require("../../assets/images/png/right-arrow.png");
  return (
    <div className="landing-page-bg h-[90vh]">
      {/* Header */}
      <div className="bg-primary text-start">
        <h2 className="font-medium text-white py-6 pl-3 text-xl">
          Course Registration
        </h2>
      </div>
      <div className="text-center flex flex-col justify-center items-center py-7">
        <img src={logo} className="h-[184px]" alt="" />
        <h1 className="font-bold text-secondary text-2xl">
          Tamilnadu Technical Training School and Paramedical Institute
        </h1>
        <p className="font-medium text-black text-md">Run By</p>
        <h1 className="font-medium text-lg ">
          Tamilnadu Santhome Educational and Charitable Trust
        </h1>
      </div>
      <Link to={'/register-form'} className="bg-primary text-white fixed bottom-8 whitespace-nowrap left-1/2 transform -translate-x-1/2 flex items-center justify-center py-4 px-7 text-xl font-medium">
        Register Now <img src={rightArrow} alt="" className="h-5 pl-3" />{" "}
      </Link>
    </div>
  );
}

export default MobileLandingPage;
