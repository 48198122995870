import React from "react";
import { Routes, Route } from "react-router-dom";
import MobileLandingPage from "./landing";
import MobileRegistrationForm from "./form";
function MobileNavigation() {
  return (
    <Routes>
      <Route Component={MobileLandingPage} path="/" />
      <Route Component={MobileRegistrationForm} path="/register-form" />
    </Routes>
  );
}

export default MobileNavigation;
