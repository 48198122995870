import React from "react";
import MobileCourseDetailsForm from "./courseDetailsForm";
import MobilePersonalInfoForm from "./personalInfoForm";
import MobileTermsandConditions from "./termsandconditionsForm";
import MobileConfirmationDetails from "./confirmationDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function MobileRegistrationForm() {
  return (
    <div>
      {/* Header */}
      <div className="bg-primary text-start">
        <h2 className="font-medium text-white py-6 pl-3 text-xl">
          Course Registration
        </h2>
      </div>
      <div className="max-w-md mb-[80px] mx-auto p-6">
        <MobileCourseDetailsForm />
        <MobilePersonalInfoForm />
        <MobileTermsandConditions />
        <MobileConfirmationDetails />
      </div>
    </div>
  );
}

export default MobileRegistrationForm;
