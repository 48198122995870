import React from "react";

function DisabledConfirmImg() {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="44"
        height="44"
        rx="22"
        fill="#BBBBBB"
        fillOpacity="0.35"
      />
      <path
        d="M23.7603 13.6002L15.5503 22.2902C15.2403 22.6202 14.9403 23.2702 14.8803 23.7202L14.5103 26.9602C14.3803 28.1302 15.2203 28.9302 16.3803 28.7302L19.6003 28.1802C20.0503 28.1002 20.6803 27.7702 20.9903 27.4302L29.2003 18.7402C30.6203 17.2402 31.2603 15.5302 29.0503 13.4402C26.8503 11.3702 25.1803 12.1002 23.7603 13.6002Z"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3896 15.0498C22.8196 17.8098 25.0596 19.9198 27.8396 20.1998"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 32H31.5"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DisabledConfirmImg;
