import React from "react";

function SelectedCourseImg() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#6457FF" />
      <path
        d="M32 14.6697V26.7397C32 27.6997 31.22 28.5997 30.26 28.7197L29.93 28.7597C27.75 29.0497 24.39 30.1597 22.47 31.2197C22.21 31.3697 21.78 31.3697 21.51 31.2197L21.47 31.1997C19.55 30.1497 16.2 29.0497 14.03 28.7597L13.74 28.7197C12.78 28.5997 12 27.6997 12 26.7397V14.6597C12 13.4697 12.97 12.5697 14.16 12.6697C16.26 12.8397 19.44 13.8997 21.22 15.0097L21.47 15.1597C21.76 15.3397 22.24 15.3397 22.53 15.1597L22.7 15.0497C23.33 14.6597 24.13 14.2697 25 13.9197V17.9997L27 16.6697L29 17.9997V12.7797C29.27 12.7297 29.53 12.6997 29.77 12.6797H29.83C31.02 12.5797 32 13.4697 32 14.6697Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 15.4902V30.4902"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 12.7803V18.0002L27 16.6702L25 18.0002V13.9202C26.31 13.4002 27.77 12.9803 29 12.7803Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SelectedCourseImg;
