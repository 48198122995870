import React, { useEffect, useState } from "react";
import MobileAccordion from "../../components/mobile/accordion";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setFirstFormStatus, setSecondFormStatus, setThirdFormStatus } from "../../redux/formStatusStore";
import { FailureToast, SuccessToast } from "../../components/mobile/toastify";
import axios from "axios";
import centerList from "../../json/centerList.json"

const MobileCourseDetailsForm = () => {
  const { firstStepSubmitted } = useSelector((state) => state.formStatusStore);
  const dispatch = useDispatch();
  const [courselist, setCourseList] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("firstStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("course-details-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          course: "",
          center: "",
          qualification: [
            {
              examname: "",
              regno: "",
              marks: "",
              nameofboard: "",
              school: "",
              from: "",
              to: "",
            },
          ],
        };
  };
  const courseForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      course: Yup.string().required("This field is Required"),
      center: Yup.string().required("This field is Required"),
      qualification: Yup.array().of(
        Yup.object().shape({
          examname: Yup.string().required("This field is Required"),
          regno: Yup.string().required("This field is Required"),
          marks: Yup.number()
            .typeError("Must be a number")
            .required("Required")
            .max(100, "Must be less than or equal to 100"),
          nameofboard: Yup.string().required("This field is Required"),
          school: Yup.string().required("Please fill school/college name"),
          from: Yup.string().required("Please fill the start date"),
          to: Yup.string().required("Please fill the completed date"),
        })
      ),
    }),
    onSubmit: (values) => {
      dispatch(setFirstFormStatus(true));
      localStorage.setItem("firstStepSubmitted", true);
      localStorage.setItem("course-details-info", JSON.stringify(values));
      SuccessToast("Data Saved! ");
      // navigate("/personal-details");
    },
  });

  const addQualification = () => {
    const newQualification = {
      examname: "",
      regno: "",
      marks: "",
      nameofboard: "",
      school: "",
      from: "",
      to: "",
    };
    courseForm.setFieldValue("qualification", [
      ...courseForm.values.qualification,
      newQualification,
    ]);
  };

  const removeQualification = (index) => {
    if (courseForm.values.qualification.length === 1) {
      FailureToast("Qualification Must be atleast one!");
      return null;
    }
    const qualifications = [...courseForm.values.qualification];
    qualifications.splice(index, 1);
    courseForm.setFieldValue("qualification", qualifications);
  };

  const HandleUpdateQualificationSection = (index, e) => {
    const { name, value } = e.target;
    courseForm.setFieldValue(`qualification[${index}].${name}`, value);
  };

  const getDefaultValueByValue = (options, value) => {
    if (value === "") return "";
    return options.filter((values) => values.value === value);
  };

  const InnerContent = () => {
    return (
      <div className="py-2">
        <div className="mb-4">
          <div className="my-2">
            <label className="font-medium">
              Course <span className="text-red-500">*</span>
            </label>
            <Select
              isSearchable={true}
              onChange={(value) =>
                courseForm.setFieldValue(`course`, value.value)
              }
              defaultValue={getDefaultValueByValue(
                courselist,
                courseForm.values.course
              )}
              options={courselist}
            />
            <p className="text-red-500 font-medium text-md">
              {courseForm.touched.course && courseForm?.errors?.course}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Center <span className="text-red-500">*</span>
            </label>
            <Select
              isSearchable={false}
              onChange={(value) =>
                courseForm.setFieldValue(`center`, value.value)
              }
              defaultValue={getDefaultValueByValue(
                centerList,
                courseForm.values.center
              )}
              options={centerList}
            />
            <p className="text-red-500 font-medium text-md">
              {courseForm.touched.center && courseForm?.errors?.center}
            </p>
          </div>
        </div>
        <div className="border rounded-t-md ">
          <p className="text-medium bg-primary p-2 text-white rounded-t-md ">
            Educational Qualification
          </p>
          <div className="flex items-end gap-x-2">
            <FontAwesomeIcon
              icon={faPlus}
              className="text-primary font-medium border border-primary mt-3 p-2"
              onClick={() => addQualification()}
            />
            <p className="font-medium text-black">Add Qualification</p>
          </div>
          {courseForm?.values?.qualification &&
            courseForm?.values?.qualification.length > 0 &&
            courseForm.values.qualification.map((items, index) => (
              <div className="my-4" key={index}>
                <div className="flex justify-between items-center bg-primary p-2">
                  <p className="text-medium  text-white">
                    Qualification - {index + 1}
                  </p>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-red-500"
                    onClick={() => removeQualification(index)}
                  />
                </div>

                <div className="my-2 px-2">
                  <label className="font-medium">
                    Examination Passed <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id={`examname-${index}`}
                    className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                    name="examname"
                    defaultValue={
                      courseForm.values.qualification[index]["examname"]
                    }
                    onChange={(e) => HandleUpdateQualificationSection(index, e)}
                  />
                  <p className="text-red-500 font-medium text-md">
                    {courseForm.touched?.qualification?.[index]["examname"] &&
                      courseForm?.errors?.qualification?.[index]?.["examname"]}
                  </p>
                </div>
                <div className="my-2 px-2">
                  <label className="font-medium">
                    Registration Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                    name="regno"
                    defaultValue={
                      courseForm.values.qualification[index]["regno"]
                    }
                    onChange={(e) => HandleUpdateQualificationSection(index, e)}
                  />
                  <p className="text-red-500 font-medium text-md">
                    {courseForm.touched?.qualification?.[index]["regno"] &&
                      courseForm?.errors?.qualification?.[index]?.["regno"]}
                  </p>
                </div>
                <div className="my-2 px-2">
                  <label className="font-medium">
                    Marks (%) <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                    name="marks"
                    defaultValue={
                      courseForm.values.qualification[index]["marks"]
                    }
                    onChange={(e) => HandleUpdateQualificationSection(index, e)}
                  />
                  <p className="text-red-500 font-medium text-md">
                    {courseForm.touched?.qualification?.[index]["marks"] &&
                      courseForm?.errors?.qualification?.[index]?.["marks"]}
                  </p>
                </div>
                <div className="my-2 px-2">
                  <label className="font-medium">
                    Name of Board <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                    defaultValue={
                      courseForm.values.qualification[index]["nameofboard"]
                    }
                    name="nameofboard"
                    onChange={(e) => HandleUpdateQualificationSection(index, e)}
                  />
                  <p className="text-red-500 font-medium text-md">
                    {courseForm.touched?.qualification?.[index][
                      "nameofboard"
                    ] &&
                      courseForm?.errors?.qualification?.[index]?.[
                        "nameofboard"
                      ]}
                  </p>
                </div>
                <div className="my-2 px-2">
                  <label className="font-medium">
                    School / College Name{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                    name="school"
                    defaultValue={
                      courseForm.values.qualification[index]["school"]
                    }
                    onChange={(e) => HandleUpdateQualificationSection(index, e)}
                  />
                  <p className="text-red-500 font-medium text-md">
                    {courseForm.touched?.qualification?.[index]["school"] &&
                      courseForm?.errors?.qualification?.[index]?.["school"]}
                  </p>
                </div>
                <div className="my-2 px-2">
                  <label className="font-medium">
                    Period of Study <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center gap-x-2 mt-1">
                    <div className="w-1/2">
                      <label className="font-medium">From</label>
                      <input
                        type="date"
                        className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                        name="from"
                        defaultValue={
                          courseForm.values.qualification[index]["from"]
                        }
                        onChange={(e) =>
                          HandleUpdateQualificationSection(index, e)
                        }
                      />
                      <p className="text-red-500 font-medium text-md">
                        {courseForm.touched?.qualification?.[index]["from"] &&
                          courseForm?.errors?.qualification?.[index]?.["from"]}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <label className="font-medium">To</label>
                      <input
                        type="date"
                        className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
                        name="to"
                        defaultValue={
                          courseForm.values.qualification[index]["to"]
                        }
                        onChange={(e) =>
                          HandleUpdateQualificationSection(index, e)
                        }
                      />
                      <p className="text-red-500 font-medium text-md">
                        {courseForm.touched?.qualification?.[index]["to"] &&
                          courseForm?.errors?.qualification?.[index]?.["to"]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="text-right px-2 mb-2">
            <button className="bg-secondary px-5 text-white py-2 font-medium">
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={courseForm.handleSubmit}>
        <MobileAccordion
          acordionTitle={"Course Information"}
          accordionContent={InnerContent()}
          accordionTitleClass={"bg-primary rounded-t-md text-white"}
          outerDivClass={"my-2"}
          savedAccordion={firstStepSubmitted}
          openAccordion={true}
          EnableEdit={() => {
            dispatch(setFirstFormStatus(false))
            dispatch(setSecondFormStatus(true))
            dispatch(setThirdFormStatus(true))
          }}
        />
      </form>
    </div>
  );
};

export default MobileCourseDetailsForm;
