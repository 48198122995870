import {
  faAngleDown,
  faAngleUp,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

function MobileAccordion({
  outerDivClass,
  acordionTitle,
  accordionContent,
  accordionTitleClass,
  accordionContentClass,
  openAccordion,
  savedAccordion,
  disabled,
  EnableEdit
}) {
  const [showContent, setShowContent] = useState(
    openAccordion === undefined ? false : openAccordion
  );
  if (disabled) {
    return (
      <div className={`max-w-md mx-auto bg-gray-300`}>
        <div className={`border p-4 flex justify-between items-center`}>
          <h2 className="text-gray-500 font-medium">{acordionTitle}</h2>
          <FontAwesomeIcon className="text-xl" icon={faAngleDown} />
        </div>
      </div>
    );
  }
  if (savedAccordion) {
    return (
      <div className={`max-w-md mx-auto bg-gray-300`}>
        <div className={`border p-4 flex justify-between items-center`}>
          <h2 className="text-gray-500 font-medium">{acordionTitle}</h2>
          <FontAwesomeIcon className="text-blue-700 text-xl" icon={faPen} onClick={EnableEdit} />
        </div>
      </div>
    );
  }
  return (
    <div className={`max-w-md mx-auto bg-gray-100 ${outerDivClass}`}>
      <div
        className={`border p-4 flex justify-between items-center ${accordionTitleClass}`}
        onClick={() => setShowContent(!showContent)}
      >
        <h2 className="font-medium">{acordionTitle}</h2>
        {!showContent ? (
          <FontAwesomeIcon icon={faAngleDown} />
        ) : (
          <FontAwesomeIcon icon={faAngleUp} />
        )}
      </div>
      {showContent && (
        <div
          className={`border border-gray-400 border-t-0 p-4 ${accordionContentClass}`}
        >
          {accordionContent}
        </div>
      )}
    </div>
  );
}

export default MobileAccordion;
